<template>

    <div>

        <div class="container-lg">
            <div class="auth-container">
                <div class="auth-form">
                    <h2>Create an account</h2>

                    <!-- Alert messages -->
                    <Alerts :_success="success" :_error="error"></Alerts>

                    <form id="individual" @submit.prevent="createAccount()">
                        
                        <!-- User type dropdown -->
                        <div class="mb-3 form-group">
                            <label class="form-label">Register as</label>
                            <select v-model="user.type" class="form-select" required>
                                <option value="individual">Individual</option>
                            </select>
                        </div>

                        <!-- Name input -->
                        <div class="mb-3 form-group">
                            <label class="form-label">Name <span class="required">*</span></label>
                            <input type="text" v-model="user.name" class="form-control" required :disabled="wait" />
                        </div>

                        <!-- Email input -->
                        <div class="mb-3 form-group" :class="{ 'form-group--error': $v.user.email.$error }">
                            <label class="form-label form__label" for="user_email">Email <span class="required">*</span></label>
                            <input 
                                class="form__input  form-control" :class="{'form-control--error': $v.user.email.$error }"
                                v-model.trim="$v.user.email.$model"
                                v-on:input="$v.user.email.$touch"
                            />
                            <div v-if="$v.user.email.$dirty" class="mt-2">
                                <span class="input-error" v-if="!$v.user.email.required">
                                    <font-awesome-icon icon="exclamation-circle"/>
                                    Email is required
                                </span>
                                <span class="input-error" v-if="!$v.user.email.email">
                                    <font-awesome-icon icon="exclamation-circle"/>
                                    Please enter a valid email address
                                </span>
                            </div>
                        </div><!-- End email input -->

                        <!-- Create password -->
                        <div class="mb-3 position-relative">
                            <label class="form-label">Create new password <span class="required">*</span></label>
                            <div class="input-has-toggle">
                                <input 
                                    v-model="user.password" 
                                    class="form-control" 
                                    required 
                                    :disabled="wait"
                                    @input="password_check"
                                    v-bind:type="[showPassword ? 'text' : 'password']" 
                                />
                                <span v-if="user.password" class="input-toggle" @click="showPassword = !showPassword">
                                    <font-awesome-icon v-if="!showPassword" icon="eye-slash"/>
                                    <font-awesome-icon v-else icon="eye"/>
                                </span>
                            </div>                                                        
                            <ul class="pass-check my-3" v-if="user.password">
                                <li class="frmValidation" :class="{'frmValidation--passed' : user.password.length > 8}"><font-awesome-icon :icon="user.password.length > 8 ? 'check-circle' : 'times-circle'"/>Longer than 8 characters</li>
                                <li class="frmValidation" :class="{'frmValidation--passed' :has_uppercase && has_lowercase }"><font-awesome-icon :icon="has_uppercase ? 'check-circle' : 'times-circle'"/>Has uppercase & lowercase letters</li>
                                <li class="frmValidation" :class="{'frmValidation--passed' : has_number }"><font-awesome-icon :icon="has_number ? 'check-circle' : 'times-circle'"/>Has a number</li>
                                <li class="frmValidation mb-0" :class="{'frmValidation--passed' : has_special }"><font-awesome-icon :icon="has_special ? 'check-circle' : 'times-circle'"/>Has a special character</li>
                            </ul>
                            <div class="form-text mt-2">Your password MUST be longer than 8 characters, contain both uppercase and lowercase letters, include a number and special character.</div>
                        </div><!-- End create password -->

                        <!-- Confirm password -->
                        <div class="mb-3 form-group">
                            <label class="form-label">Confirm password <span class="required">*</span></label>
                            <div class="input-has-toggle">
                                <input v-model="user.password_confirmation" class="form-control" v-bind:type="[showPassword ? 'text' : 'password']">
                                <span v-if="user.password_confirmation" class="input-toggle" @click="showPassword = !showPassword">
                                    <font-awesome-icon v-if="!showPassword" icon="eye-slash"/>
                                    <font-awesome-icon v-else icon="eye"/>
                                </span>
                            </div>
                        </div><!-- End confirm password -->

                        <!-- Recaptcha -->
                        <div class="mb-0">
                            <vue-recaptcha
                                ref="recaptcha"
                                size="invisible"
                                @verify="onVerified"
                                @expired="onExpired"
                                :sitekey="sitekey">
                            </vue-recaptcha>
                        </div><!-- End recaptcha -->

                        <!-- Create account button -->
                        <div class="mb-3">
                            <button type="submit" :disabled="wait || !passwordPassed" class="custom-btn custom-btn-lg custom-btn-secondary custom-btn-full">Create Account</button>
                        </div>

                    </form><!-- End registration form -->

                    <p class="login-link-small">
                        Have an account?
                        <router-link to="/login">Sign in here</router-link>
                    </p>
                </div><!-- End auth-form -->

                <!-- Auth footer -->
                <div class="auth-footer">
                    <ul>
                        <li><router-link to="/">Sabasi</router-link></li>
                        <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                        <li><router-link to="/">Terms &amp; Conditions</router-link></li>
                    </ul>
                </div>           
                     
            </div><!-- End auth-container -->
        </div><!-- End container-lg -->

    </div>

</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import Alerts from "../Helpers/Alerts";
import {email, minLength, required} from "vuelidate/lib/validators";

export default {
    name: "Register",
    components: {Alerts, VueRecaptcha},
    data() {
        return {
            showPassword: false,
            tab: 'individual',
            user: {
                type: 'individual',
                name: '',
                email: '',
                address: '',
                password: '',
                password_confirmation: '',
                recaptcha_token: '',
            },
            individual: '',
            organisation: '',
            sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,

            has_number: false,
            has_lowercase: false,
            has_uppercase: false,
            has_special: false,
        }
    },
    validations: {
        user: {
            email: {
                required,
                email: email
            },
            password: {
                required,
                minLength: minLength(8)
            }
        }
    },
    watch: {},
    computed: {
        isOrg() {
            return this.user.type == 'organization'
        },
        passwordPassed() {
            return this.has_number
                && this.has_lowercase
                && this.has_uppercase
                && this.has_special
        }
    },
    methods: {
        setTab(tab) {
            this.tab = tab
        },
        onVerified: function (token) {
            this.user.recaptcha_token = token
            window.scroll(0, 0)
            this.clear()
            this.wait = true;
            this.success = "Creating account please wait..."
            this.$http.post("api/account", this.user).then(response => {
                let data = response.data.data;
                let message = `Welcome ${data.name}. Redirecting you to your dashbaord`;

                localStorage.setItem(process.env.VUE_APP_NAME + "_token", data.token);
                localStorage.setItem(process.env.VUE_APP_NAME + "_user", JSON.stringify(data.user));

                this.$toaster.success = message
                setTimeout(() => (window.location.href = '/dashboard'), 600)

            }).catch(error => {
                this.showError(error);
            }).then(() => {
                this.wait = false;
            });
        },
        onExpired: function () {
            this.$refs.recaptcha.reset();
        },
        createAccount() {
            this.$refs.recaptcha.execute();
        },
        password_check: function () {
            this.has_number = /\d/.test(this.user.password);
            this.has_lowercase = /[a-z]/.test(this.user.password);
            this.has_uppercase = /[A-Z]/.test(this.user.password);
            this.has_special = /[!@#$%^/&*/)|(+=._-]/.test(this.user.password);
        }
    }
}
</script>

<style scoped>
</style>
